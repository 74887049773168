/**
 * Функция для получения параметра из localStorage учитывая срок хранения
 *
 * @param key название параметра
 * @returns
 */
export function getLocalStorageWithExpiry(key) {
  const itemStr = window.localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
