
		import Async from "/app/node_modules/@preact/async-loader/async-legacy.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!/app/node_modules/babel-loader/lib/index.js??ref--4!/app/src/routes/contacts/index.jsx");
				typeof cb === 'function' && cb(result);
			});
		}

		export default Async(load);
	