import { useEffect, useState } from 'react';
import { getCurrentUrl, route } from 'preact-router';

import { useCheckCert } from '../../hooks/useCheckCert/useCheckCert';

import '../../style/cert-block.css';

export const CertificateBlock = () => {
  const { isCertFound } = useCheckCert();
  const [isInstallButtonClicked, setIsInstallButtonClicked] = useState(false);

  useEffect(() => {
    setIsInstallButtonClicked(false);
    if (isInstallButtonClicked && getCurrentUrl() !== '/') {
      route('/');
    }
  }, [isInstallButtonClicked]);

  const handleClickInstallButton = (e) => {
    if (getCurrentUrl() !== '/') e.preventDefault();
    setIsInstallButtonClicked(true);
  };

  return (
    <div className={`cert-block ${isCertFound ? 'cert-block__green' : 'cert-block__orange'}`}>
      <div className="responsive cert-block__content">
        <div className="cert-block__icon">
          {isCertFound ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.66797 16.0003C1.66797 8.08424 8.08522 1.66699 16.0013 1.66699C23.9174 1.66699 30.3346 8.08424 30.3346 16.0003C30.3346 23.9164 23.9174 30.3337 16.0013 30.3337C8.08522 30.3337 1.66797 23.9164 1.66797 16.0003ZM21.865 12.5307C22.1579 12.2378 22.1579 11.7629 21.865 11.47C21.5721 11.1771 21.0972 11.1771 20.8043 11.47L14.668 17.6063L12.5316 15.47C12.2387 15.1771 11.7639 15.1771 11.471 15.47C11.1781 15.7629 11.1781 16.2378 11.471 16.5307L14.1376 19.1973C14.4305 19.4902 14.9054 19.4902 15.1983 19.1973L21.865 12.5307Z"
                fill="#4BB34B"
              />
            </svg>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9987 30.333C23.9148 30.333 30.332 23.9158 30.332 15.9997C30.332 8.08359 23.9148 1.66634 15.9987 1.66634C8.08262 1.66634 1.66537 8.08359 1.66536 15.9997C1.66536 23.9158 8.08261 30.333 15.9987 30.333Z"
                fill="#FFA000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.418 21.3333C17.418 22.1157 16.7837 22.75 16.0013 22.75C15.2189 22.75 14.5846 22.1157 14.5846 21.3333C14.5846 20.5509 15.2189 19.9167 16.0013 19.9167C16.7837 19.9167 17.418 20.5509 17.418 21.3333ZM16.0013 18.0833C15.5871 18.0833 15.2513 17.7475 15.2513 17.3333L15.2513 10.6667C15.2513 10.2525 15.5871 9.91667 16.0013 9.91667C16.4155 9.91667 16.7513 10.2525 16.7513 10.6667L16.7513 17.3333C16.7513 17.7475 16.4155 18.0833 16.0013 18.0833Z"
                fill="white"
              />
            </svg>
          )}
        </div>
        <p className="cert-block__description">
          {isCertFound ? (
            <>Всё в порядке! У вас установлена необходимая версия сертификата</>
          ) : (
            <>
              У вас отсутствует необходимый для работы сертификат.{' '}
              <a href="#installation" onClick={handleClickInstallButton}>
                Скачайте его ниже
              </a>
              .
            </>
          )}
        </p>
      </div>
    </div>
  );
};
