import { useEffect, useState } from 'react';

import { getLocalStorageWithExpiry } from './helpers/getLocalStorageWithExpiry';
import { setLocalStorageWithExpiry } from './helpers/setLocalStorageWithExpiry';

export const useCheckCert = () => {
  const [isCertFound, setIsCertFound] = useState(false);

  useEffect(() => {
    const checkCert = () => {
      fetch('https://mc.sovcombank.ru')
        .then(() => window.localStorage.setItem('certFound', true)) // Указываем, что серт найден
        .catch(() => setLocalStorageWithExpiry('certFound', false, 20)) // Указываем, что серта нет и даем срок жизни 20 минут
        .finally(() => {
          window.localStorage.setItem('certChecked', true); // Сохраняем информацию, что сертификат проверяли
          setIsCertFound(window.localStorage.getItem('certFound') === 'true');
        });
    };

    const isCertChecked = window.localStorage.getItem('certChecked') === 'true';
    const isCertFound = getLocalStorageWithExpiry('certFound');

    // Если проверка на серт не происходила или срок жизни результата проверки серта подошел к концу, вызываем запрос
    if (!isCertChecked || isCertFound === null) {
      checkCert();
    } else {
      setIsCertFound(window.localStorage.getItem('certFound') === 'true');
    }
  }, []);

  return { isCertFound };
};
