const AppLinks = () => (
  <div className="app_links">
    <a className="app_link" href="https://scb.im/iosapp" target="blanc">
      <svg width="32" height="32" viewBox="0 0 21 26">
        <path
          d="M17.6509 13.7133C17.6511 15.9195 18.9633 17.9142 20.9887 18.787L21 19.0085C21 19.0085 19.3024 24.3286 16.0629 25.5991C14.2497 26.3111 12.7682 25.1358 12.497 25.0194C11.2354 24.4287 9.7576 24.5363 8.59485 25.3036C6.01485 26.8288 4.05285 24.414 4.05285 24.414C3.27715 23.596 2.60059 22.6893 2.0372 21.7127C0.795101 19.6437 0.101571 17.2917 0.0223081 14.8795C-0.0879657 13.2545 0.211831 11.6278 0.894144 10.1489C1.51886 8.85309 2.53224 7.7846 3.79296 7.09246C4.58318 6.68636 5.45336 6.46001 6.34123 6.42962C7.59703 6.47157 8.82713 6.79685 9.93963 7.38118C10.3846 7.60799 10.9023 7.64507 11.3751 7.48396C12.3346 7.04312 13.3379 6.70466 14.3683 6.47421C15.6116 6.21622 16.9033 6.3322 18.0808 6.80752C19.0927 7.20705 19.928 7.95628 20.4349 8.91924C18.7141 9.90159 17.6516 11.7312 17.6509 13.7133ZM10.5795 6.0676C10.5795 6.0676 10.4964 0.640922 15.8589 0C15.8378 6.31021 10.5795 6.0676 10.5795 6.0676Z"
          fill="#A1AFBF"
        />
      </svg>
    </a>

    <a className="app_link" href="https://scb.im/androidapp" target="blanc">
      <svg width="32" height="32" viewBox="0 0 22 23">
        <path
          d="M13.6308 11.1072L0.632324 0.0457053C1.2112 -0.0699293 1.81239 0.0356888 2.31718 0.341704L17.1757 8.19732L13.6308 11.1072ZM0.0600475 22.2343C0.0253764 22.1275 0.00518063 22.0165 0 21.9044V0.698438C0.00869671 0.666191 0.0195533 0.634565 0.0324963 0.603775L12.9907 11.6327L0.0600475 22.2343ZM13.6237 12.1696L16.6756 14.7672L2.31858 22.3628C1.83064 22.6743 1.25573 22.8211 0.678223 22.7818L13.6237 12.1696ZM17.9599 8.61411L21.288 10.3746C22.2374 10.9143 22.2374 11.7874 21.288 12.3307L17.4534 14.3589L14.2645 11.6447L17.9599 8.61411Z"
          fill="#A1AFBF"
        />
      </svg>
    </a>

    <a className="app_link" href="https://hva.im/D2fFvH" target="blanc">
      <svg width="32" height="32" viewBox="0 0 24 24">
        <path
          d="M18.8308 0H5.16915C2.31431 0 0 2.31431 0 5.16916V18.8308C0 21.6857 2.31431 24 5.16915 24H18.8308C21.6857 24 24 21.6857 24 18.8308V5.16916C24 2.31431 21.6857 0 18.8308 0Z"
          fill="#A1AFBF"
        />
        <path
          className="jss63"
          d="M5.84984 1.56843C5.6688 1.56867 5.49527 1.64054 5.3674 1.76825C5.23953 1.89595 5.16779 2.06903 5.16797 2.24942C5.16814 2.4298 5.2402 2.60273 5.36829 2.73018C5.49639 2.85764 5.67004 2.92919 5.85107 2.92911C6.03209 2.92903 6.20568 2.85732 6.33366 2.72975C6.46164 2.60218 6.53354 2.42919 6.53355 2.24881V2.24745C6.53322 2.06707 6.46101 1.89421 6.3328 1.76687C6.20459 1.63953 6.03087 1.56813 5.84984 1.56838V1.56843ZM18.0627 1.56843C17.8816 1.56867 17.7081 1.64055 17.5802 1.76825C17.4524 1.89595 17.3806 2.06903 17.3808 2.24942C17.381 2.42982 17.453 2.60276 17.5812 2.73021C17.7093 2.85767 17.8829 2.92922 18.064 2.92911C18.245 2.92901 18.4186 2.85729 18.5465 2.72972C18.6745 2.60215 18.7464 2.42917 18.7464 2.24881V2.24745C18.746 2.06707 18.6738 1.89421 18.5456 1.76687C18.4174 1.63953 18.2437 1.56813 18.0627 1.56838V1.56843Z"
          fillOpacity="0.2"
          fill="#ffffff"
        ></path>
        <path
          className="jss63"
          d="M5.82292 2.1551C5.58563 2.16783 5.59765 2.51295 5.59765 2.51295C6.87286 4.99626 9.22093 6.21432 11.9287 6.21445C14.6328 6.2105 17.0042 4.97901 18.2777 2.49897C18.2777 2.49897 18.3282 2.03197 17.8312 2.19384C16.5252 4.35863 14.331 5.63929 11.9303 5.64333C9.52501 5.64393 7.33868 4.37672 6.03014 2.20782C5.94563 2.16703 5.87768 2.15216 5.82292 2.1551ZM14.4535 11.8078L13.728 13.9685L13.0217 11.8094H12.3323L13.4454 14.9502H13.9821L14.7092 12.887L15.4355 14.9502H15.9766L17.0873 11.8094H16.4151L15.7076 13.9685L14.9821 11.8078H14.4535ZM10.5999 11.8086L9.19224 14.9486H9.854L10.1258 14.3407L10.145 14.2948H11.6076L11.8942 14.9486H12.574L11.1791 11.827L11.1667 11.8086L10.5999 11.8086ZM20.7945 11.809V14.9466H21.4342V11.809L20.7945 11.809ZM2.45239 11.8094V14.9514H3.10092V13.6754H4.56473V14.9514H5.21327V11.8094H4.56473V13.0771H3.10092V11.8094L2.45239 11.8094ZM8.21423 11.8102V13.6086C8.21423 14.1195 7.9564 14.3922 7.48794 14.3922C7.01685 14.3922 6.75723 14.1119 6.75723 13.5871V11.8122H6.1091V13.6087C6.1091 14.4927 6.60887 14.9993 7.47952 14.9993C8.35845 14.9993 8.86236 14.4829 8.86236 13.5827V11.8102L8.21423 11.8102ZM17.6099 11.8114V14.949H20.0229V14.3771H18.2493V13.599H19.4285V13.0268H18.2493V12.3838H19.9612V11.8114L17.6099 11.8114ZM10.8745 12.6038L11.3371 13.6557L11.3683 13.728H10.3879L10.4188 13.6557L10.8745 12.6038Z"
          fill="#ffffff"
        />
      </svg>
    </a>
  </div>
);

const SocialLinks = () => (
  <div className="social_links">
    <a className="social_link" href="https://vk.com/sovcombank" target="blanc">
      <svg
        width="16"
        height="16"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3677 7.17138C16.768 7.5794 17.6366 8.26408 17.479 8.96112C17.3337 9.60097 16.3785 9.3676 15.4512 9.40469C14.3925 9.44951 13.765 9.47269 13.1283 8.96112C12.8284 8.71847 12.6522 8.431 12.3648 8.10953C12.1036 7.81896 11.7744 7.29811 11.3262 7.31821C10.5209 7.35839 10.7729 8.48046 10.4869 9.2455C6.01103 9.95027 4.21356 7.18529 2.62784 4.50223C1.8597 3.20242 0.75 0.411172 0.75 0.411172L3.91527 0.400354C3.91527 0.400354 4.9307 2.24728 5.19962 2.72331C5.42836 3.12824 5.68028 3.44971 5.93994 3.81137C6.15786 4.1112 6.50251 4.69851 6.87963 4.6506C7.49321 4.57178 7.60449 2.19164 7.22428 1.39414C7.07282 1.07112 6.70962 0.958295 6.33405 0.848561C6.46078 0.0479697 9.88416 -0.118949 10.4375 0.502359C11.2412 1.40496 9.88107 3.91801 10.9815 4.6506C12.527 3.84073 13.8469 0.449811 13.8469 0.449811L17.5532 0.472994C17.5532 0.472994 16.9736 2.30601 16.3662 3.11896C16.0122 3.59499 14.8376 4.65523 14.884 5.44192C14.9211 6.06477 15.8762 6.67062 16.3677 7.17138Z"
          fill="#A1AFBF"
        />
      </svg>
    </a>
    <a className="social_link" href="https://ok.ru/paosovcombank" target="blanc">
      <svg
        width="16"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.85506 4.46525C8.85506 6.57934 7.06068 8.29665 4.86318 8.29665C2.66505 8.29665 0.870663 6.57934 0.870663 4.46525C0.870663 2.34231 2.66505 0.625 4.86318 0.625C7.06068 0.625 8.85506 2.34231 8.85506 4.46525ZM6.82374 4.46525C6.82374 3.41768 5.94297 2.5704 4.86318 2.5704C3.78275 2.5704 2.90199 3.41704 2.90262 4.46525C2.90262 5.50397 3.78339 6.35125 4.86318 6.35125C5.94297 6.35125 6.82374 5.50397 6.82374 4.46525ZM1.3204 10.5103C0.317061 9.88856 -0.122691 9.52463 0.264619 8.76328C0.492709 8.33111 1.11695 7.96654 1.94402 8.59458C1.94402 8.59458 3.06109 9.45071 4.86432 9.45071C6.66693 9.45071 7.78463 8.59458 7.78463 8.59458C8.61232 7.9697 9.23404 8.33111 9.46466 8.76328C9.84628 9.52463 9.40653 9.88793 8.40319 10.5128L8.40382 10.5134C7.56096 11.029 6.40029 11.2331 5.63389 11.3102C5.52759 11.2078 6.45475 12.097 7.30459 12.912C7.97527 13.5552 8.5978 14.1523 8.62622 14.1793C9.48488 15.0259 8.08791 16.3496 7.21978 15.532C6.64178 14.9671 5.79845 14.1558 4.91531 13.3062L4.87127 13.2638L2.52277 15.5289C1.65527 16.3433 0.257669 15.0083 1.1258 14.1761C3.71375 11.6868 4.09787 11.3173 4.10857 11.307C4.10299 11.3124 4.00042 11.4111 4.10866 11.307C4.10863 11.307 4.1086 11.307 4.10857 11.307C3.35164 11.2299 2.17334 11.0372 1.3204 10.5103Z"
          fill="#A1AFBF"
        />
      </svg>
    </a>
    <a className="social_link" href="https://t.me/sovcombankofficial" target="blanc">
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.715203 5.31639L14.337 0.0642803C14.9693 -0.164123 15.5214 0.218512 15.3166 1.17451L15.3177 1.17333L12.9984 12.1002C12.8265 12.8749 12.3662 13.0633 11.7222 12.6983L8.19014 10.0952L6.48652 11.7364C6.29815 11.9248 6.13921 12.0837 5.77423 12.0837L6.02501 8.48932L12.571 2.57554C12.8559 2.32477 12.5074 2.18349 12.1319 2.43309L4.04237 7.52625L0.555084 6.43839C-0.201945 6.19822 -0.218428 5.68136 0.715203 5.31639Z"
          fill="#A1AFBF"
        />
      </svg>
    </a>
  </div>
);

export const LinksForUsers = () => {
  return (
    <>
      <AppLinks />
      <SocialLinks />
    </>
  );
};
