import 'preact/debug';

// import '@fontsource/montserrat/400.css';
// import '@fontsource/montserrat/500.css';
// import '@fontsource/montserrat/600.css';
// import '@fontsource/montserrat/700.css';
// import '@fontsource/montserrat/800.css';
import './style/index.css';

import App from './components/app';

export default App;
