import { useState } from 'react';
import { LinksForBusiness } from './LinksForBusiness';
import { LinksForUsers } from './LinksForUsers';

import '../../style/footer.css';

const ContactsForUser = () => {
  return (
    <>
      <div className="phones">
        <div className="phone-wrapper">
          <a className="phone" href="tel:+88002006696">
            8 (800) 200 66 96
          </a>
          <span className="caption">Звонок по России (бесплатно)</span>
        </div>
        <div className="phone-wrapper">
          <a className="phone" href="tel:+74959880000">
            +7 (495) 988 00 00
          </a>
          <span className="caption">Для звонков из-за рубежа (платно)</span>
        </div>
      </div>
      <div className="footer-links">
        <LinksForUsers />
      </div>
    </>
  );
};

const ContactsForBusiness = () => {
  return (
    <>
      <div className="phones">
        <div className="phone-wrapper" style={{ margin: 'auto' }}>
          <a className="phone" href="tel:+88002006696">
            8 (800) 100 10 20
          </a>
          <span className="caption">Звонок по России (бесплатно)</span>
        </div>
      </div>
      <div className="footer-links">
        <LinksForBusiness />
      </div>
    </>
  );
};

export const Footer = () => {
  return (
    <footer>
      <div className="footer__head">
        <div className="footer__contacts">
          <div>
            <a href="/contacts" style={{ color: ' #003790', fontWeight: 500 }}>
              Контакты{' '}
            </a>
            для физических лиц
          </div>
          <ContactsForUser />
        </div>

        <div className="footer__contacts">
          <div>
            <a href="/contacts" style={{ color: ' #003790', fontWeight: 500 }}>
              Контакты{' '}
            </a>
            для юридических лиц
          </div>
          <ContactsForBusiness />
        </div>
      </div>

      <p className="footer_desc">
        Генеральная лицензия Банка России №963 от 5 декабря 2014 г. © 2004-2022, ПАО «Совкомбанк»
        Все права защищены
      </p>
    </footer>
  );
};
