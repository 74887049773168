import { Route, Router } from 'preact-router';

import { Header } from './Header';
import { Footer } from './Footer';
import { CertificateBlock } from './CertificateBlock';

import MainPage from '../routes/main';
import WindowsPage from '../routes/windows';
import MacPage from '../routes/mac';
import LinuxPage from '../routes/linux';
import IosPage from '../routes/ios';
import AndroidPage from '../routes/android';
import HuaweiPage from '../routes/huawei';
import NotFoundPage from '../routes/notfound';
import ContactsPage from '../routes/contacts';

const routes = [
  { id: 1, path: '/windows', component: WindowsPage },
  { id: 2, path: '/mac', component: MacPage },
  { id: 3, path: '/linux', component: LinuxPage },
  { id: 4, path: '/ios', component: IosPage },
  { id: 5, path: '/android', component: AndroidPage },
  { id: 6, path: '/huawei', component: HuaweiPage },
  { id: 7, path: '/contacts', component: ContactsPage },
];

const App = () => {
  return (
    <>
      <CertificateBlock />
      <div id="preact_root" className="responsive">
        <Header />

        <div>
          <Router>
            <Route exact path="/" component={MainPage} />
            <NotFoundPage default />
            {routes.map((item) => (
              <Route path={item.path} key={item.id} component={item.component} />
            ))}
          </Router>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default App;
