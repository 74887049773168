/**
 * Функция для сохранения параметра в localStorage учитывая срок хранения
 *
 * @param key название параметра
 * @param value значение параметра
 * @param mins время жизни в минутах
 */
export function setLocalStorageWithExpiry(key, value, mins) {
  const now = new Date();
  const minsToMilliseconds = mins * 60 * 1000;

  const item = {
    value,
    expiry: now.getTime() + minsToMilliseconds,
  };

  window.localStorage.setItem(key, JSON.stringify(item));
}
