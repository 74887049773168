import { useState, useRef } from 'react';
import { route } from 'preact-router';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import '../../style/header.css';

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setOpenMenu(false));

  const Menu = () => {
    return openMenu ? (
      <div className="menu">
        <a className="menu-item" href="https://halvacard.ru/lk" target="_blank" rel="noreferrer">
          Халва - Совкомбанк
        </a>
        <a
          className="menu-item"
          href="https://online.sovcombank.ru/"
          target="_blank"
          rel="noreferrer"
        >
          Совкомбанк - Бизнес
        </a>
        <a className="menu-item" href="https://bk.sovcombank.ru/" target="_blank" rel="noreferrer">
          Correqts Ф-Л Корпоративный
        </a>
        <a
          className="menu-item"
          href="https://scb-private.fintender.ru/home/User/Login"
          target="_blank"
          rel="noreferrer"
        >
          Финтендер
        </a>
      </div>
    ) : null;
  };

  return (
    <header>
      <svg
        onClick={() => route('/', true)}
        className="header-logo"
        style={{ cursor: 'pointer' }}
        width="138"
        height="17"
        viewBox="0 0 138 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15_1645)">
          <path
            d="M26.9024 3.14941C28.8133 3.14941 30.3735 3.95312 31.0303 4.55235L29.9307 5.83886C29.073 5.16056 28.0006 4.77924 26.8875 4.75683C24.4862 4.75683 22.9854 6.33585 22.9854 8.4999C22.9854 10.6639 24.4089 12.2401 26.9172 12.2401C28.1283 12.2186 29.29 11.7776 30.1863 10.9991L31.2265 12.3282C30.5697 12.987 28.9322 13.8504 26.9143 13.8504C23.3807 13.8504 21.1934 11.5784 21.1934 8.4999C21.1934 5.42138 23.4312 3.14941 26.9024 3.14941Z"
            fill="#003790"
          />
          <path
            d="M37.8165 3.14941C41.0856 3.14941 43.4631 5.42138 43.4631 8.4999C43.4631 11.5784 41.0856 13.8504 37.8165 13.8504C34.5474 13.8504 32.1699 11.5784 32.1699 8.4999C32.1699 5.42138 34.5445 3.14941 37.8165 3.14941ZM37.8165 12.2373C40.0335 12.2373 41.68 10.6441 41.68 8.49706C41.68 6.35005 40.0425 4.75399 37.8165 4.75399C35.5906 4.75399 33.9531 6.34721 33.9531 8.49706C33.9531 10.6469 35.5995 12.2401 37.8165 12.2401V12.2373Z"
            fill="#003790"
          />
          <path
            d="M54.7194 10.647C54.7194 12.155 53.709 13.6147 51.0937 13.6147H45.3906V3.39087H50.7579C52.9303 3.39087 54.1845 4.67453 54.1845 6.12291C54.1845 6.94082 53.7862 7.68773 52.8382 8.28696C53.7922 8.588 54.7194 9.24403 54.7194 10.647ZM47.147 4.99261V7.71045H50.5439C51.7535 7.71045 52.3805 7.06578 52.3805 6.30751C52.3805 5.60604 51.8456 5.00681 50.7727 5.00681L47.147 4.99261ZM51.2007 12.0073C52.44 12.0073 52.9155 11.3484 52.9155 10.6328C52.9155 9.8603 52.3478 9.22983 51.2334 9.22983H47.147V12.0073H51.2007Z"
            fill="#003790"
          />
          <path
            d="M61.7528 8.33814L66.5078 13.6148H64.1659L60.1925 9.14185H58.1598V13.6148H56.4004V3.39093H58.1598V7.60827H60.1925L64.056 3.38525H66.3949L61.7528 8.33814Z"
            fill="#003790"
          />
          <path
            d="M71.9199 3.14941C75.189 3.14941 77.5665 5.42138 77.5665 8.4999C77.5665 11.5784 75.189 13.8504 71.9199 13.8504C68.6508 13.8504 66.2733 11.5784 66.2733 8.4999C66.2733 5.42138 68.6508 3.14941 71.9199 3.14941ZM71.9199 12.2373C74.1369 12.2373 75.7834 10.6441 75.7834 8.49706C75.7834 6.35005 74.1459 4.75399 71.9199 4.75399C69.694 4.75399 68.0565 6.34721 68.0565 8.49706C68.0565 10.6469 69.7088 12.2401 71.9199 12.2401V12.2373Z"
            fill="#003790"
          />
          <path
            d="M89.4222 3.38525H91.1221V13.6091H89.3628V6.01506L85.8441 10.5079H84.7742L81.2555 6.02074V13.6148H79.4961V3.39093H81.196L85.3091 8.63634L89.4222 3.38525Z"
            fill="#003790"
          />
          <path
            d="M99.1814 7.56277C101.535 7.56277 102.822 8.88051 102.822 10.576C102.822 12.2714 101.535 13.6147 99.3033 13.6147H93.4902V3.39087H101.826V4.99829H95.2496V7.56845L99.1814 7.56277ZM99.226 12.0073C100.45 12.0073 101.009 11.3655 101.009 10.5873C101.009 9.80918 100.459 9.16735 99.226 9.16735H95.2496V12.0073H99.226Z"
            fill="#003790"
          />
          <path
            d="M112.594 13.6147L111.432 11.0588H106.38L105.215 13.6147H103.289L107.94 3.39087H109.881L114.532 13.6147H112.594ZM107.057 9.52234H110.746L108.909 5.44416L107.057 9.52234Z"
            fill="#003790"
          />
          <path
            d="M123.773 3.38525H125.532V13.6091H123.773V9.22989H117.58V13.6148H115.82V3.39093H117.58V7.62247H123.773V3.38525Z"
            fill="#003790"
          />
          <path
            d="M133.257 8.33814L138.012 13.6148H135.67L131.696 9.14185H129.664V13.6148H127.904V3.39093H129.664V7.60827H131.696L135.56 3.38525H137.896L133.257 8.33814Z"
            fill="#003790"
          />
          <path
            d="M0 8.5C0 10.7543 0.937137 12.9163 2.60525 14.5104C4.27337 16.1045 6.53582 17 8.89489 17C9.01376 17 9.13264 17 9.25151 17V13.7681H8.89489C7.45455 13.7362 6.08447 13.167 5.07762 12.1822C4.07077 11.1975 3.50707 9.87534 3.50707 8.49858C3.50707 7.12182 4.07077 5.7997 5.07762 4.81495C6.08447 3.8302 7.45455 3.26099 8.89489 3.22903H9.25151V0C9.13264 0 9.01376 0 8.89489 0C6.53582 0 4.27337 0.895533 2.60525 2.48959C0.937137 4.08365 0 6.24566 0 8.5"
            fill="#FF4B5F"
          />
          <path
            d="M10.6713 0.17041V4.58939H8.88812C8.34163 4.57531 7.79772 4.66601 7.28852 4.85614C6.77932 5.04627 6.31515 5.33196 5.92341 5.69636C5.53167 6.06075 5.2203 6.49646 5.00769 6.97775C4.79508 7.45905 4.68555 7.97617 4.68555 8.49859C4.68555 9.02101 4.79508 9.53814 5.00769 10.0194C5.2203 10.5007 5.53167 10.9364 5.92341 11.3008C6.31515 11.6652 6.77932 11.9509 7.28852 12.141C7.79772 12.3312 8.34163 12.4219 8.88812 12.4078H10.6713V16.8296C12.6816 16.4391 14.4888 15.3965 15.7869 13.8785C17.0849 12.3605 17.794 10.4604 17.794 8.50001C17.794 6.53965 17.0849 4.63955 15.7869 3.12155C14.4888 1.60355 12.6816 0.560969 10.6713 0.17041Z"
            fill="#003790"
          />
        </g>
        <defs>
          <clipPath id="clip0_15_1645">
            <rect width="138" height="17" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div ref={ref} className="lk" onClick={() => setOpenMenu((prev) => !prev)}>
        <div className="lk-link">Личный кабинет</div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.83102 0H14.8684C17.3871 0 19.4413 2 19.4413 4.44V15.56C19.4413 18.01 17.3871 20 14.8478 20H9.82069C7.302 20 5.2375 18.01 5.2375 15.57V10.77H11.6375L9.98585 12.37C9.67618 12.67 9.67618 13.16 9.98585 13.46C10.1407 13.61 10.3471 13.68 10.5536 13.68C10.7497 13.68 10.9562 13.61 11.111 13.46L14.1252 10.55C14.28 10.41 14.3626 10.21 14.3626 10C14.3626 9.8 14.28 9.6 14.1252 9.46L11.111 6.55C10.8013 6.25 10.2955 6.25 9.98585 6.55C9.67618 6.85 9.67618 7.34 9.98585 7.64L11.6375 9.23H5.2375V4.45C5.2375 2 7.302 0 9.83102 0ZM0.448486 9.9999C0.448486 9.5799 0.803581 9.2299 1.22969 9.2299H5.23719V10.7699H1.22969C0.803581 10.7699 0.448486 10.4299 0.448486 9.9999Z"
            fill="#003790"
          />
        </svg>
        <Menu />
      </div>
    </header>
  );
};
